import { faBedFront, faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';
import { faAngleDown, faAngleRight, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import defaultCompany from '../../assets/default-company.svg';
import logoWhite from '../../assets/logo-white.svg';
import { PropertyGroupOverviewAPIResult } from '../../types/api/searchTypes';
import { GeneralCMSResult } from '../../types/cms/generalTypes';
import { SearchCMSResult } from '../../types/cms/searchTypes';
import { LanguageKey } from '../../types/common';
import { insertIntoString } from '../../utils/common';
import { PROPERTY_AVAILABLE, PROPERTY_RENTED, PROPERTY_RESERVED, PROPERTY_TO_RENT, PROPSTEP } from '../../utils/constants';
import { getImageFromApiURl } from '../../utils/http';
import { getCompanyLink, getPropertyGroupLink, getPropertyLink } from '../../utils/links';
import { getPrice } from '../../utils/property';
import { Heading } from 'legacy-ui';
import { Pill } from 'legacy-ui';
import { Text } from 'legacy-ui';
import {
  OverviewHeader,
  OverviewHeaderDetails,
  OverviewHeaderDetailsCompany,
  OverviewHeaderDetailsList,
  OverviewHeaderDetailsManaged,
  OverviewHeaderImage,
  OverviewHeaderImageInner,
  OverviewHeaderImageInnerLabel,
  OverviewProperties,
  OverviewPropertiesEmpty,
  OverviewPropertiesList,
  OverviewPropertiesMore,
  OverviewWrapper,
} from './styles';
import { useWhiteLabel } from 'providers/WhiteLabelProvider';
import PropertyImageThumbnail from 'components/PropertyImageThumbnail';

interface PropertyGroupListItemProps {
  propertyGroupOverview: PropertyGroupOverviewAPIResult;
  generalTexts: GeneralCMSResult;
  fromCMS: SearchCMSResult;
}

const PropertyGroupOverviewListItem = ({ propertyGroupOverview, generalTexts, fromCMS }: PropertyGroupListItemProps) => {
  const { propertyGroup, company, properties, propertiesTotal } = propertyGroupOverview;
  const [firstImage] = propertyGroup.images;
  const { theme } = useWhiteLabel();
  const router = useRouter();
  const { locale } = router;
  const [showAllProperties, setShowAllProperties] = useState(false);
  const propertyGroupLink = `${getPropertyGroupLink(propertyGroup, locale as LanguageKey)}`;

  return (
    <OverviewWrapper>
      <OverviewHeader propstepManaged={propertyGroup.handledBy === PROPSTEP}>
        <OverviewHeaderImage>
          <OverviewHeaderImageInner>
            <Link href={propertyGroupLink} passHref>
              <a>
                <PropertyImageThumbnail
                  src={getImageFromApiURl(firstImage?.name)}
                  alt={propertyGroup.name}
                  backgroundColor={theme.color.propertyCardBackgroundColor}
                  logoColor={theme.color.propertyCardLogoColor}
                />
                {propertyGroup.upcomingProject && (
                  <OverviewHeaderImageInnerLabel>
                    <Text size="s">{generalTexts.Misc.Upcoming}</Text>
                  </OverviewHeaderImageInnerLabel>
                )}
              </a>
            </Link>
          </OverviewHeaderImageInner>
        </OverviewHeaderImage>
        <OverviewHeaderDetails>
          <Heading size="xs" tag="h2">
            <Link href={propertyGroupLink} passHref>
              <a>{propertyGroup.name}</a>
            </Link>
          </Heading>
          <OverviewHeaderDetailsList>
            <li>
              <span>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <Text>{propertyGroup.location.city}</Text>
              </span>
            </li>
            <li>
              <Link href={propertyGroupLink} passHref>
                <a>
                  <Text>{generalTexts.Misc.SeeDetails}</Text>
                  <FontAwesomeIcon icon={faAngleRight} />
                </a>
              </Link>
            </li>
          </OverviewHeaderDetailsList>
          <Link href={getCompanyLink(company, locale as LanguageKey)} passHref>
            <a>
              <OverviewHeaderDetailsCompany>
                <div>
                  <Image src={company.logo ? getImageFromApiURl(company.logo?.name) : defaultCompany} width={20} height={20} alt={company.name} />
                </div>
                <div>
                  <Text size="s" weight="m">
                    {company.name}
                  </Text>
                </div>
              </OverviewHeaderDetailsCompany>
            </a>
          </Link>
          {propertyGroup.handledBy === PROPSTEP && (
            <OverviewHeaderDetailsManaged>
              <div>
                <Text size="s" weight="m">
                  {generalTexts.Misc.ManagedBy}
                </Text>
              </div>
              <div>
                <Image src={logoWhite} alt="Propstep" width="70" height="12" />
              </div>
            </OverviewHeaderDetailsManaged>
          )}
        </OverviewHeaderDetails>
      </OverviewHeader>
      <OverviewProperties>
        {propertiesTotal > 0 ? (
          <div>
            <OverviewPropertiesList>
              {properties.slice(0, showAllProperties ? properties.length : 3).map((property, index) => (
                <li key={index} onClick={() => router.push(`${getPropertyLink(property, locale as LanguageKey)}`)}>
                  <div>
                    <Heading>{property.name}</Heading>
                  </div>
                  <div>
                    <div>{property.propertyDetails.size}m²</div>
                    <div>
                      <FontAwesomeIcon icon={faBedFront} />
                      <span>{property.propertyDetails.rooms}</span>
                    </div>
                    <div>{getPrice(property, generalTexts.Misc.PerMonth)}</div>
                    <div>
                      {property.transactionStatus === PROPERTY_AVAILABLE && <Pill color="green">{generalTexts.Misc.StatusAvailable}</Pill>}
                      {property.transactionStatus === PROPERTY_RESERVED && <Pill color="orange">{generalTexts.Misc.StatusReserved}</Pill>}
                      {property.transactionStatus === PROPERTY_RENTED && (
                        <Pill color="red">
                          {property.transactionDetails.type === PROPERTY_TO_RENT ? generalTexts.Misc.StatusRented : generalTexts.Misc.StatusSold}
                        </Pill>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </OverviewPropertiesList>
            {properties.length > 3 && (
              <OverviewPropertiesMore>
                {showAllProperties ? (
                  <a onClick={() => setShowAllProperties(false)}>
                    <Heading>
                      {generalTexts.Misc.ShowLess} <FontAwesomeIcon icon={faAngleUp} />
                    </Heading>
                  </a>
                ) : (
                  <a onClick={() => setShowAllProperties(true)}>
                    <Heading>
                      {`${insertIntoString(generalTexts.Misc.ShowMore, 1, String(properties.length - 3))} `}
                      <FontAwesomeIcon icon={faAngleDown} />
                    </Heading>
                  </a>
                )}
              </OverviewPropertiesMore>
            )}
          </div>
        ) : (
          <OverviewPropertiesEmpty>
            <Text>{propertyGroup.interestListHidden ? fromCMS.ResultsItemNoAvailableProperties : fromCMS.ResultsItemJoinInterest}</Text>
          </OverviewPropertiesEmpty>
        )}
      </OverviewProperties>
    </OverviewWrapper>
  );
};

export default PropertyGroupOverviewListItem;
